/* stylelint-disable */
$datepicker__background-color: theme('colors.black.5');
$datepicker__border-color: theme('colors.black.5');
$datepicker__selected-color: #34A7BC;
$datepicker__text-color: theme('colors.black.75');
$datepicker__header-color: theme('colors.black.75');
$datepicker__border-radius: 0;
$datepicker__day-margin: 2px;
$datepicker__font-size: 14px;
$datepicker__font-family: "Open Sans", sans-serif;
$datepicker__item-size: 32px;
$datepicker__margin: 12px;
$datepicker__navigation-size: 6px;
$datepicker__triangle-size: 0;


@import "~react-datepicker/src/stylesheets/datepicker";

.react-datepicker-wrapper {
  @apply block;
}

.react-datepicker--header-no-title {
  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 8px;
  }

  .react-datepicker__navigation {
    margin-top: 8px;
  }
}