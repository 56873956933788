.layout-image {
  @screen lg {
    margin-right: calc(50% - 50vw);
  }

  &__col {
    @screen lg {
      @apply max-w-full flex-grow;

      flex-basis: 0;
      padding-right: 96px !important;
    }

    @media (min-width: 1920px) {
      max-width: 738px;
      padding-right: 180px;
    }
  }

  &__out-col {
    @screen lg {
      flex-basis: 50vw;
      max-width: 50vw;
      width: 50vw;
    }

    @media (min-width: 1920px) {
      max-width: 846px;
    }
  }

  &__bg {
    @apply object-cover w-full h-auto;

    @screen lg {
      @apply h-full;

      min-height: 491px;
    }
  }
}
