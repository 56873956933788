.cta-image-full-wrapper {
  @apply bg-black-5 bg-no-repeat bg-center-right mx-auto;

  background-size: auto 100%;
  max-width: 1920px;
}

@media (max-width: 1023px) {
  .cta-image-full-wrapper {
    background-image: none !important;
  }
}
