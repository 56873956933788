.checkbox {
  @apply relative;

  input {
    @apply absolute top-auto overflow-hidden whitespace-nowrap;

    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    white-space: nowrap;
    width: 1px;
  }

  label {
    @apply relative block mb-0 pl-32 text-12 xl:text-13 leading-19 xl:leading-22 text-black-75 cursor-pointer;

    &::before,
    &::after {
      @apply absolute top-0 left-0 w-20 h-20;

      content: "";
    }

    &::before {
      @apply bg-black-5 border-2 border-black-5;
    }

    &::after {
      @apply flex items-center justify-center opacity-0;

      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 10' width='12px' height='10px' fill='rgb(52, 167, 188)'><path d='M10.12 0L4.5 6 1.88 3.2 0 5.2 4.5 10 12 2z'/></svg>");
    }
  }

  /* stylelint-disable no-descending-specificity */
  input:checked ~ label {
    &::before {
      @apply bg-black-5 border-primary;
    }

    &::after {
      @apply opacity-100;
    }
  }

  input:disabled ~ label {
    @apply cursor-not-allowed;
  }

  &--error input ~ label {
    &::before {
      @apply bg-error-75;
    }
  }

  @at-root .accessibility .checkbox input:focus ~ label {
    &::before {
      outline: 2px solid #00f;
      outline-offset: 1px;
    }
  }
}
