.cms {
  @apply text-15 xl:text-16 leading-25 xl:leading-28 text-black-75;

  p {
    @apply mb-12;
  }

  a {
    @apply font-semibold underline;
  }

  ul {
    li {
      @apply flex mb-8;

      &::before {
        @apply text-primary-dk mr-8;

        content: "•";
      }
    }
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;

    li {
      @apply flex mb-8;

      counter-increment: my-awesome-counter;

      &::before {
        @apply text-primary-dk mr-8;

        content: counter(my-awesome-counter) ".";
        min-width: 1em;
      }
    }
  }
}
