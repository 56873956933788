.switch {
  @apply relative;

  input {
    @apply absolute top-auto overflow-hidden whitespace-nowrap;

    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    white-space: nowrap;
    width: 1px;
  }

  label {
    @apply relative block mb-0 text-12 xl:text-13 leading-19 xl:leading-22 text-black-75 cursor-pointer font-body font-normal;

    padding-left: 56px;

    &::before,
    &::after {
      @apply absolute top-0 left-0;

      content: "";
    }

    &::before {
      @apply bg-black-5 border-2 border-black-5 w-48 h-24 rounded-switch;
    }

    &::after {
      @apply flex items-center justify-center w-16 h-16 bg-black-25 rounded-full top-4 left-4 transition-transform;
    }
  }

  /* stylelint-disable no-descending-specificity */
  input:checked ~ label {
    &::before {
      @apply border-primary;
    }

    &::after {
      @apply bg-primary transform translate-x-24;
    }
  }

  input:disabled ~ label {
    @apply cursor-not-allowed;
  }

  @at-root .accessibility .switch input:focus ~ label {
    &::before {
      outline: 2px solid #00f;
      outline-offset: 1px;
    }
  }
}
