.radio {
  @apply relative;

  input {
    @apply absolute top-auto overflow-hidden whitespace-nowrap;

    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    white-space: nowrap;
    width: 1px;
  }

  label {
    @apply relative block mb-0 pl-32 text-12 xl:text-13 leading-19 xl:leading-22 text-black-75 cursor-pointer;

    &::before,
    &::after {
      @apply absolute top-0 left-0 rounded-full;

      content: "";
    }

    &::before {
      @apply w-20 h-20 bg-black-5 border-2 border-black-5;
    }

    &::after {
      @apply bg-primary opacity-0 w-10 h-10;

      content: "";
      transform: translate3d(5px, 5px, 0);
    }
  }

  /* stylelint-disable no-descending-specificity */
  input:checked ~ label {
    &::before {
      @apply bg-black-5 border-primary;
    }

    &::after {
      @apply opacity-100;
    }
  }

  input:disabled ~ label {
    @apply cursor-not-allowed;
  }

  &--error input ~ label {
    &::before {
      @apply bg-error-75;
    }
  }

  @at-root .accessibility .radio input:focus ~ label {
    &::before {
      outline: 2px solid #00f;
      outline-offset: 1px;
    }
  }
}
