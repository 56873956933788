.message {
  @apply relative pl-12;

  @screen lg {
    @apply pl-40;
  }

  &__box-content {
    @apply mb-16 xl:mb-24;
  }

  &__content {
    @apply p-12 xl:p-16  bg-primary-lt2;
  }

  &__date {
    @apply mt-8 text-10 text-right text-black-75;
  }

  &::after {
    border: 12px solid transparent;
    border-right-color: theme("colors.primary.lt2");
    border-top-color: theme("colors.primary.lt2");
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;

    @screen lg {
      border-width: 20px;
    }
  }

  &.is-owner {
    @apply pl-0 pr-12;

    @screen lg {
      @apply pr-40;
    }

    .message__content {
      @apply bg-primary-lt;
    }

    &::after {
      border: 12px solid transparent;
      border-left-color: theme("colors.primary.lt");
      border-top-color: theme("colors.primary.lt");
      left: auto;
      right: 0;

      @screen lg {
        border-width: 20px;
      }
    }
  }
}
