.sidebar {
  @apply fixed top-0 left-0 h-full bg-primary-lt50;

  width: 486px;

  &__inner {
    @apply overflow-y-auto pt-64 pb-12 px-96;

    height: calc(100% - 80px);
  }
}
