@layer components {
  .loader {
    @apply mx-auto inline-block border-6;

    animation: kf-loading 1.1s linear infinite;
    border-color: rgba(#34a7bc, 0.4);
    border-left-color: #34a7bc;
    transform: translateZ(0);

    &,
    &::after {
      @apply w-40 h-40 rounded-full;
    }

    &--secondary {
      border-color: rgba(#000, 0.4);
      border-left-color: #000;
    }

    &--small {
      @apply border-4;

      &,
      &::after {
        @apply w-32 h-32;
      }
    }
  }
}

@keyframes kf-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
