.menu-mobile-content {
  @apply fixed top-0 left-0 h-full
    transform -translate-x-full
    bg-primary-lt50;

  transition: transform 0.2s ease;
  width: 264px;

  &.is-open {
    @apply translate-x-0;
  }

  &__inner {
    @apply overflow-y-auto pt-16 px-16;

    height: calc(100% - 80px);
  }
}

.menu-mobile-toggle {
  @apply inline-block w-36 h-36 py-4;

  > div {
    @apply relative w-full h-full;
  }

  span {
    @apply block absolute left-2/4 w-full h-4 bg-primary;

    transition: transform 0.2s, opacity 0.1s;

    &:nth-of-type(1),
    &:nth-of-type(3) {
      transform: translate3d(-50%, 0, 0);
    }

    &:nth-of-type(1) {
      @apply top-0;
    }

    &:nth-of-type(2) {
      @apply top-2/4;

      transform: translate3d(-50%, -50%, 0);
    }

    &:nth-of-type(3) {
      @apply bottom-0;
    }
  }

  &.is-open {
    span {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        @apply top-2/4;
      }

      &:nth-of-type(1) {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

      &:nth-of-type(2) {
        @apply opacity-0;
      }

      &:nth-of-type(3) {
        @apply bottom-auto;

        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }
    }
  }
}
