.steps {
  position: relative;

  &::before {
    @screen lg {
      @apply border-b-2 border-primary-lt border-dashed absolute top-1/2 -translate-x-1/2 w-full;

      content: "";
    }
  }
}
