.file {
  @apply relative;

  input {
    @apply absolute top-auto overflow-hidden whitespace-nowrap;

    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    white-space: nowrap;
    width: 1px;
  }

  &__controls {
    @apply absolute flex pointer-events-none items-center right-0 top-0;
  }

  @at-root .accessibility .file input:focus ~ label {
    outline: 2px solid #00f;
    outline-offset: 1px;
  }
}
