.hero {
  @apply bg-primary-lt50;

  &__wrapper {
    @apply bg-cover bg-no-repeat bg-center-right mx-auto h-full;

    max-width: 1920px;
  }
}

@media (max-width: 1023px) {
  .hero__wrapper {
    background-image: none !important;
  }
}
