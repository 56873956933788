@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 62.5%;
  }

  body {
    @apply text-15 xl:text-16 overflow-x-hidden font-body;
  }

  a,
  button,
  input,
  textarea,
  select {
    appearance: none;

    &:focus {
      outline: none;
    }
  }

  a,
  button {
    @at-root .accessibility &:focus {
      outline: 2px solid blue;
    }
  }

  input[type="number"] {
    appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

@layer components {
  .container {
    @apply w-full mx-auto px-16;

    @screen xl {
      @apply px-12;

      max-width: 1224px;
    }

    &--dashboard {
      @screen xl {
        @apply mx-0;

        max-width: 1386px;
      }

      @screen xxl {
        @apply px-48;
      }
    }
  }

  .row {
    @apply flex flex-wrap -mx-8;

    @screen xl {
      @apply -mx-12;
    }
  }

  .col {
    @apply w-full px-8;

    @screen xl {
      @apply px-12;
    }
  }

  .list {
    @at-root ul#{&} {
      li {
        @apply flex mb-8;

        &::before {
          @apply text-primary-dk mr-8;

          content: "•";
        }
      }
    }

    @at-root ol#{&} {
      counter-reset: my-awesome-counter;
      list-style: none;

      li {
        @apply flex mb-8;

        counter-increment: my-awesome-counter;

        &::before {
          @apply text-primary-dk mr-8;

          content: counter(my-awesome-counter) ".";
          min-width: 1em;
        }
      }
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  [type="number"] {
    appearance: textfield;
  }
}

@layer utilities {
  .text-inherit {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
}
